import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Coffee = function Coffee(_ref) {
  var info = _ref.info;
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "Coffee This Week \u2014"), React.createElement(Title, null, info.coffeeHouse), React.createElement(Author, null, info.coffeeBlend));
};

var Wrapper = styled.div.withConfig({
  displayName: "Coffee__Wrapper",
  componentId: "sc-2co8hy-0"
})(["padding:3rem 4rem 3rem 3rem;min-width:15vw;border-bottom:3px solid ", ";"], function (props) {
  return props.theme.main;
});
var Title = styled.p.withConfig({
  displayName: "Coffee__Title",
  componentId: "sc-2co8hy-1"
})(["font-size:2.2rem;font-family:'Work Sans';color:", ";font-weight:500;margin:0;"], function (props) {
  return props.theme.main;
});
var Author = styled.p.withConfig({
  displayName: "Coffee__Author",
  componentId: "sc-2co8hy-2"
})(["margin:0.65rem 0 0 0;font-size:1.2rem;font-family:'Work Sans';"]);
export default Coffee;