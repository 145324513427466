import React from 'react';
import styled from 'styled-components';
import Hello from 'components/Panes/Hello';
import Pattern from 'components/Panes/Pattern';
import Me from 'components/Panes/Me';
import Marquee from 'components/Panes/Marquee';
import Picture from 'components/Panes/Picture';
import Projects from 'components/Panes/Projects';
import Stack from 'components/Panes/Stack';
import Skills from 'components/Panes/Skills';
import Links from 'components/Panes/Links';
import Book from 'components/Panes/Book';
import Coffee from 'components/Panes/Coffee';
import Blog from 'components/Panes/Blog';
import Color from 'components/Panes/Color';

var Grid = function Grid(_ref) {
  var setColor = _ref.setColor,
      info = _ref.info;
  return React.createElement(Wrapper, null, React.createElement(One, null, React.createElement(Hello, null), React.createElement(Pattern, null), React.createElement(Me, null), React.createElement(Marquee, {
    text: "Born and raised and based in New York City. Born and raised and based in New York City. Born and raised and based in New York City."
  }), React.createElement(Marquee, {
    invert: true,
    text: "Filipinx. They/Them. Cancer Sun, Virgo Moon. Filipinx. They/Them. Cancer Sun, Virgo Moon. Filipinx. They/Them. Cancer Sun, Virgo Moon."
  }), React.createElement(Picture, null)), React.createElement(Two, null, React.createElement(Pattern, null), React.createElement(Projects, null)), React.createElement(Three, null, React.createElement(Blog, null), React.createElement(Book, {
    info: info
  }), React.createElement(Coffee, {
    info: info
  }), React.createElement(Links, null), React.createElement(Pattern, null), React.createElement(Color, {
    setColor: setColor
  })));
};

export default Grid;
var Wrapper = styled.div.withConfig({
  displayName: "Grid__Wrapper",
  componentId: "sc-1fd281r-0"
})(["border:3px solid ", ";box-shadow:12px 12px ", ";width:90vw;height:90vh;display:flex;> div{border-right:3px solid ", ";}@media (max-width:1200px){flex-direction:column;grid-template-columns:1fr;box-shadow:8px 10px ", ";margin:8vh 0 10vh 0;height:auto;}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
});
var One = styled.div.withConfig({
  displayName: "Grid__One",
  componentId: "sc-1fd281r-1"
})(["flex-basis:30%;display:grid;min-height:650px;grid-template-columns:auto 1fr;grid-template-rows:auto 1fr;grid-template-areas:'hello me' 'pattern me' 'marquee marquee' 'marqueetwo marqueetwo' 'picture picture';div:nth-child(1){grid-area:hello;}div:nth-child(2){grid-area:pattern;border-right:3px solid ", ";}div:nth-child(3){grid-area:me;}div:nth-child(4){grid-area:marquee;}div:nth-child(5){grid-area:marqueetwo;}div:nth-child(6){grid-area:picture;border-right:0;height:30vh;@media (max-width:1000px){max-height:700px;}}"], function (props) {
  return props.theme.main;
});
var Two = styled.div.withConfig({
  displayName: "Grid__Two",
  componentId: "sc-1fd281r-2"
})(["flex-basis:50%;display:grid;grid-template-columns:minmax(180px,20%) auto;grid-template-areas:'pattern pattern' 'projects projects' 'stack skills';div:nth-child(1){grid-area:pattern;height:9vh;border-bottom:3px solid ", ";}div:nth-child(2){grid-area:projects;}div:nth-child(3){grid-area:stack;}div:nth-child(4){grid-area:skills;}@media (max-width:1200px){border-bottom:3px solid ", ";grid-template-areas:'pattern pattern' 'projects projects' 'stack stack' 'skills skills';}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
});
var Three = styled.div.withConfig({
  displayName: "Grid__Three",
  componentId: "sc-1fd281r-3"
})(["flex-grow:1;display:flex;flex-direction:column;"]);