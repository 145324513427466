import React from 'react';
import styled from 'styled-components';

var Marquee = function Marquee(props) {
  return React.createElement(Wrapper, {
    invert: props.invert
  }, React.createElement("p", null, props.text));
};

export default Marquee; // const Wrapper = styled.div`
//   background: ${props => props.theme.main};
//   color: ${props => props.theme.background};
//   max-width: 100%;
//   p {
//     margin: 0;
//     font-family: 'WORK SANS';
//     font-style: italic;
//     font-size: 1.2rem;
//     font-weight: 200;
//     padding: 1.25rem 0 1rem 0;
//     white-space: nowrap;
//     overflow: hidden;
//     text-transform: uppercase;
//   }
// `

var Wrapper = styled.div.withConfig({
  displayName: "Marquee__Wrapper",
  componentId: "babz4f-0"
})(["width:100%;margin-left:auto;margin-right:auto;overflow:hidden;white-space:nowrap;max-height:100%;background:", ";color:", ";p{float:", ";margin:0;font-family:'WORK SANS';font-style:italic;font-size:1rem;letter-spacing:2px;font-weight:200;padding:1.25rem 0 1rem 0;white-space:nowrap;overflow:hidden;text-transform:uppercase;}"], function (props) {
  return !props.invert ? props.theme.main : props.theme.background;
}, function (props) {
  return props.invert ? props.theme.main : props.theme.background;
}, function (props) {
  return props.invert ? 'left' : 'right';
});