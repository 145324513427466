import React from 'react';
import styled from 'styled-components';

var SmallHeader = function SmallHeader(props) {
  return React.createElement(Wrapper, null, props.children);
};

export default SmallHeader;
var Wrapper = styled.p.withConfig({
  displayName: "SmallHeader__Wrapper",
  componentId: "sc-159j594-0"
})(["font-family:'Spectral';font-weight:600;font-style:italic;font-size:1.1rem;text-transform:uppercase;font-weight:400;white-space:nowrap;"]);