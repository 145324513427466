import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Links = function Links(_ref) {
  var info = _ref.info;
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "Currently Reading \u2014"), React.createElement(Title, null, info.bookTitle), React.createElement(Author, null, "By ", info.bookAuthor));
};

export default Links;
var Wrapper = styled.div.withConfig({
  displayName: "Book__Wrapper",
  componentId: "sc-189j2yt-0"
})(["padding:3rem 4rem 3rem 3rem;min-width:15vw;border-bottom:3px solid ", ";"], function (props) {
  return props.theme.main;
});
var Title = styled.p.withConfig({
  displayName: "Book__Title",
  componentId: "sc-189j2yt-1"
})(["font-size:2.2rem;font-family:'Work Sans';color:", ";font-weight:500;margin:0;"], function (props) {
  return props.theme.main;
});
var Author = styled.p.withConfig({
  displayName: "Book__Author",
  componentId: "sc-189j2yt-2"
})(["margin:0.65rem 0 0 0;padding-left:3px;font-size:1.2rem;font-family:'Work Sans';"]);