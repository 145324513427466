import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Stack = function Stack() {
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "I love working in \u2014"), React.createElement("div", null, React.createElement(Tech, null, "MongoDB"), React.createElement(Tech, null, "Express"), React.createElement(Tech, null, "React"), React.createElement(Tech, null, "Node")));
};

export default Stack;
var Wrapper = styled.div.withConfig({
  displayName: "Stack__Wrapper",
  componentId: "sc-1kzmdc9-0"
})(["background:", ";color:", ";padding:10% 10% 10% 10%;display:flex;flex-direction:column;justify-content:center;align-items:center;@media (max-width:1200px){padding:5% 5%;}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.background;
});
var Tech = styled.p.withConfig({
  displayName: "Stack__Tech",
  componentId: "sc-1kzmdc9-1"
})(["font-family:'Work Sans';font-size:2.2rem;text-transform:uppercase;font-weight:200;margin:0;line-height:120%;letter-spacing:1px;:first-letter{font-weight:500;}@media (max-width:1200px){font-size:3rem;}"]);