function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  ", "\n  html {\n    font-size: 62.5%;\n  }\n\n\n\n  \n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

import { createGlobalStyle } from 'styled-components';
import modernNormalize from 'styled-modern-normalize';
var GlobalStyle = createGlobalStyle(_templateObject(), modernNormalize);
export default GlobalStyle;