import "core-js/modules/es6.function.name";
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

var Color = function Color(_ref) {
  var setColor = _ref.setColor;

  var _useState = useState('Migol Blue.'),
      name = _useState[0],
      setName = _useState[1];

  var colors = [{
    name: 'Migol Blue',
    hex: '#2c3a85'
  }, {
    name: 'Charcoal',
    hex: '#212121'
  }, {
    name: 'Infra Red',
    hex: '#cc3344'
  }, {
    name: 'Pink Dahlia',
    hex: '#b94c66'
  }, {
    name: 'Approval Green',
    hex: '#039487'
  }, {
    name: 'Aqua Obscura',
    hex: '#05696b'
  }, {
    name: 'Emberglow',
    hex: '#ea6759'
  }];
  useEffect(function () {
    var getColor = function getColor() {
      var randomColor = colors[Math.floor(Math.random() * colors.length)];
      setColor(randomColor.hex);
      setName(randomColor.name);
      console.log('randomColor:', randomColor);
    };

    getColor();
  }, []);
  return React.createElement(Wrapper, null, "Feels like ", React.createElement(Name, null, "ACAB."));
};

export default Color;
var Wrapper = styled.div.withConfig({
  displayName: "Color__Wrapper",
  componentId: "sc-1wcc5uw-0"
})(["background:", ";color:", ";font-family:'Spectral';font-weight:200;font-size:2rem;padding:1.75rem 2rem 1rem 2.5rem;"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.background;
});
var Name = styled.p.withConfig({
  displayName: "Color__Name",
  componentId: "sc-1wcc5uw-1"
})(["font-family:'Work Sans';font-weight:300;font-size:2.1rem;background:none;border:none;color:", ";letter-spacing:1px;text-decoration:underline;display:inline;"], function (props) {
  return props.theme.background;
});