import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Skills = function Skills() {
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "But I'm also pretty good with:"), React.createElement(SkillList, null, "GATSBY, GRAPHQL, REST APIS, HEADLESS WORDPRESS, STYLED COMPONENTS, PASSPORTJS, DOCKER, GIT + VERSION CONTROL!"), React.createElement(SmallHeader, null, "(And learning more all the time...)"));
};

export default Skills;
var Wrapper = styled.div.withConfig({
  displayName: "Skills__Wrapper",
  componentId: "sc-19db4bx-0"
})(["text-align:center;display:flex;flex-direction:column;justify-content:center;align-items:center;padding:20px;"]);
var SkillList = styled.p.withConfig({
  displayName: "Skills__SkillList",
  componentId: "sc-19db4bx-1"
})(["font-size:1.5rem;font-family:'Work Sans';margin:0;"]);