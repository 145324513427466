import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import BLM from '../components/Panes/BLM';
import Global from 'utils/Global';
import Grid from 'components/Grid';
import { Helmet } from 'react-helmet';

var IndexPage = function IndexPage(_ref) {
  var data = _ref.data;

  var _useState = useState('#FDFCF8'),
      themeColor = _useState[0],
      setThemeColor = _useState[1];

  var info = data.allMarkdownRemark.edges[0].node.frontmatter; // const theme = {
  //   main: themeColor,
  //   background: '#FDFCF8',
  // }

  var theme = {
    main: '#FDFCF8',
    background: '#222222'
  };

  var setColor = function setColor(color) {
    setThemeColor(color);
  };

  return React.createElement(React.Fragment, null, React.createElement(Global, null), React.createElement(Helmet, null, React.createElement("meta", {
    charSet: "utf-8"
  }), React.createElement("title", null, "Mika Busante"), React.createElement("meta", {
    name: "description",
    content: "Designer & developer."
  }), React.createElement("link", {
    rel: "canonical",
    href: "http://mikabusante.com"
  })), React.createElement(ThemeProvider, {
    theme: theme
  }, React.createElement(Site, null, React.createElement(BLM, null), React.createElement(Grid, {
    info: info,
    setColor: setColor
  }))));
};

export default IndexPage;
var Site = styled.div.withConfig({
  displayName: "pages__Site",
  componentId: "sc-6kvjaa-0"
})(["background-color:", ";color:", ";min-height:100vh;width:100vw;display:flex;flex-direction:column;align-items:center;"], function (props) {
  return props.theme.background;
}, function (props) {
  return props.theme.main;
});
export var query = "425823623";