import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';
import resume from '../../data/Busante_Mika_Resume.pdf';

var Links = function Links() {
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "Links \u2014"), React.createElement(List, null, React.createElement("li", null, React.createElement(Link, {
    href: "https://twitter.com/mikabusante"
  }, "Twitter")), React.createElement("li", null, React.createElement(Link, {
    href: "https://github.com/mikabusante"
  }, "Github")), React.createElement("li", null, React.createElement(Link, {
    href: "mailto: mika@mikabusante.com"
  }, "Email"))));
};

export default Links;
var Wrapper = styled.div.withConfig({
  displayName: "Links__Wrapper",
  componentId: "o3g6av-0"
})(["padding:1rem 4rem 2rem 3rem;min-width:15vw;border-bottom:3px solid ", ";"], function (props) {
  return props.theme.main;
});
var List = styled.ul.withConfig({
  displayName: "Links__List",
  componentId: "o3g6av-1"
})(["list-style:none;padding:0;"]);
var Link = styled.a.withConfig({
  displayName: "Links__Link",
  componentId: "o3g6av-2"
})(["font-size:2.2rem;font-family:'Work Sans';color:", ";font-weight:500;:visited{color:", ";}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
});