import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Projects = function Projects() {
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "Selected Projects \u2014"), React.createElement("div", null, React.createElement(Title, null, "Wanderlust"), React.createElement(Desc, null, "A travel booking site for solo travellers."), React.createElement(Link, {
    href: "https://gh-wanderlust.now.sh/",
    target: "_blank"
  }, "www"), React.createElement(Link, {
    href: "https://github.com/gh-wanderlust/wanderlust",
    target: "_blank"
  }, "source")), React.createElement("div", null, React.createElement(Title, null, "NamePending"), React.createElement(Desc, null, "A fullstack e-commerce site for art prints."), React.createElement(Link, {
    href: "http://name-pending.herokuapp.com/",
    target: "_blank"
  }, "www"), React.createElement(Link, {
    href: "https://github.com/1911-team-two/grace-shopper",
    target: "_blank"
  }, "source")), React.createElement("div", null, React.createElement(Title, null, "Aesthetic Tattoo"), React.createElement(Desc, null, "A homepage for a woman owned tattoo studio."), React.createElement(Link, {
    href: "https://www.aesthetictattoo.co/",
    target: "_blank"
  }, "www")), React.createElement("div", null, React.createElement(Title, null, "Stitchminder"), React.createElement(Desc, null, "A thread tracker."), React.createElement(Link, {
    href: "https://stitchminder.herokuapp.com/",
    target: "_blank"
  }, "www"), React.createElement(Link, {
    href: "https://github.com/mikabusante/stitchminder",
    target: "_blank"
  }, "source")), React.createElement("div", null, React.createElement(Title, null, "Read.me"), React.createElement(Desc, null, "An article layout experiment."), React.createElement(Link, {
    href: "https://read-me.netlify.com/",
    target: "_blank"
  }, "www"), React.createElement(Link, {
    href: "https://github.com/mikabusante/read-dot-me",
    target: "_blank"
  }, "source")), React.createElement("div", null, React.createElement(Title, null, "Stardew Lookup"), React.createElement(Desc, null, "A reference app."), React.createElement(Link, {
    href: "https://stardew-lookup.netlify.com/",
    target: "_blank"
  }, "www"), React.createElement(Link, {
    href: "https://github.com/mikabusante/stardew-lookup",
    target: "_blank"
  }, "source")));
};

export default Projects;
var Wrapper = styled.div.withConfig({
  displayName: "Projects__Wrapper",
  componentId: "jr2uo7-0"
})(["padding:0 3rem 2rem 4rem;padding-top:5rem;overflow:scroll;div:last-child{margin-bottom:15rem;}@media (max-width:1000px){padding-bottom:10rem;div:last-child{margin-bottom:0;}}"]);
var Title = styled.h2.withConfig({
  displayName: "Projects__Title",
  componentId: "jr2uo7-1"
})(["font-family:'Work Sans';font-size:3rem;font-weight:500;line-height:100%;margin:4rem 0 0 0;"]);
var Desc = styled.p.withConfig({
  displayName: "Projects__Desc",
  componentId: "jr2uo7-2"
})(["font-family:'Spectral';font-size:2.3rem;line-height:100%;margin:1rem 0 1.2rem 0;font-weight:200;"]);
var Link = styled.a.withConfig({
  displayName: "Projects__Link",
  componentId: "jr2uo7-3"
})(["font-family:'Work sans';margin:0 3.5rem 0 0px;font-size:1.1rem;text-decoration:none;text-transform:uppercase;display:inline-block;border-bottom:1px solid ", ";padding-bottom:2px;color:", ";:visited{color:", ";}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
});