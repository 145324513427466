import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Me = function Me() {
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "NICE TO MEET YOU \u2014"), React.createElement(Name, null, "I'm Mika."), React.createElement(Desc, null, "For ", React.createElement("span", {
    className: "work"
  }, "work"), ", I develop and design\xA0websites."), React.createElement(Desc, null, "For ", React.createElement("span", {
    className: "fun"
  }, "fun"), ", I chill with my dog, knit, go to art museums, and collect far too many\xA0books."));
};

export default Me;
var Wrapper = styled.div.withConfig({
  displayName: "Me__Wrapper",
  componentId: "sc-1pf5vhy-0"
})(["padding:3rem 3rem 2rem 4rem;min-height:min-content;color:", ";@media (max-width:500px){padding:10% 0% 5% 10%;}"], function (props) {
  return props.theme.main;
});
var Name = styled.h1.withConfig({
  displayName: "Me__Name",
  componentId: "sc-1pf5vhy-1"
})(["font-family:'Work Sans';font-size:4rem;font-weight:500;margin:0;"]);
var Desc = styled.p.withConfig({
  displayName: "Me__Desc",
  componentId: "sc-1pf5vhy-2"
})(["font-family:'Spectral';font-size:2.75rem;font-weight:200;margin:1.95rem 0;line-height:120%;min-width:18ch;.work{font-family:'Courier';font-style:italic;}.fun{font-family:'Shrikhand';}@media (max-width:430px){padding-right:0;max-width:70%;font-size:2rem;min-width:0;}"]);