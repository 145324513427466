import React from 'react';
import styled from 'styled-components';

var BLM = function BLM() {
  return React.createElement(Wrapper, null, "Black Lives Matter. Code is political. No justice, no peace.", ' ', React.createElement("a", {
    href: "https://blacklivesmatters.carrd.co/#donate"
  }, "Donate."));
};

export default BLM;
var Wrapper = styled.div.withConfig({
  displayName: "BLM__Wrapper",
  componentId: "sc-1jqcemk-0"
})(["background:", ";color:", ";margin-bottom:15px;width:100vw;font-size:20px;text-align:center;padding:0.5% 0;font-family:'Spectral';a{:visited{color:", ";}}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.background;
}, function (props) {
  return props.theme.background;
});