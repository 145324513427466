import React from 'react';
import styled from 'styled-components';

var Pattern = function Pattern() {
  return React.createElement(Wrapper, null);
};

export default Pattern;
var Wrapper = styled.div.withConfig({
  displayName: "Pattern__Wrapper",
  componentId: "sc-15kyp1d-0"
})(["background-image:radial-gradient( ", " 20%,transparent 20% ),radial-gradient(", " 20%,transparent 20%);background-position:0 0,5px 5px;background-size:10px 10px;height:100%;width:100%;"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.main;
});