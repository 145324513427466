import React from 'react';
import styled from 'styled-components';
import SmallHeader from 'components/Typography/SmallHeader';

var Coffee = function Coffee() {
  return React.createElement(Wrapper, null, React.createElement(SmallHeader, null, "Blog \u2014"), React.createElement(Title, null, "Coming soon!"));
};

export default Coffee;
var Wrapper = styled.div.withConfig({
  displayName: "Blog__Wrapper",
  componentId: "sc-17l6otn-0"
})(["padding:3rem 4rem 3rem 3rem;min-width:15vw;border-bottom:3px solid ", ";"], function (props) {
  return props.theme.main;
});
var Title = styled.p.withConfig({
  displayName: "Blog__Title",
  componentId: "sc-17l6otn-1"
})(["font-size:2.2rem;font-family:'Work Sans';color:", ";font-weight:500;margin:0;"], function (props) {
  return props.theme.main;
});