import React from 'react';
import styled from 'styled-components';

var Hello = function Hello() {
  return React.createElement(Wrapper, null, React.createElement("p", null, "Hello"), React.createElement("p", null, "Hello"), React.createElement("p", null, "Hello"), React.createElement("p", null, "Hello"));
};

export default Hello;
var Wrapper = styled.div.withConfig({
  displayName: "Hello__Wrapper",
  componentId: "lzp1cw-0"
})(["background-color:", ";width:min-content;max-height:115px;padding:2px 3px 3px 2px;p{color:", ";text-transform:uppercase;font-family:'Montserrat';font-weight:200;font-size:3rem;margin:0;line-height:92.3%;@media (max-width:500px){font-size:2rem;}}"], function (props) {
  return props.theme.main;
}, function (props) {
  return props.theme.background;
});