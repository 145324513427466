import React from 'react';
import styled from 'styled-components';
import flowers from 'images/flowers.png';

var Picture = function Picture() {
  return React.createElement(Wrapper, null, React.createElement("div", {
    id: "pattern"
  }), React.createElement("div", {
    id: "image"
  }), React.createElement("div", {
    id: "color"
  }));
};

export default Picture;
var Wrapper = styled.div.withConfig({
  displayName: "Picture__Wrapper",
  componentId: "sc-1tldq76-0"
})(["position:relative;display:block;overflow:hidden;height:100%;#pattern{background-image:radial-gradient( ", " 20%,transparent 20% ),radial-gradient(", " 20%,transparent 20%);background-position:0 0,3.5px 3.5px;background-size:7px 7px;height:100%;width:100%;z-index:200;position:absolute;top:0;filter:opacity(80%)}#image{background-image:url('https://media.giphy.com/media/5Z1UjlpIN9cAg/giphy.gif');background-size:cover;height:100%;background-position:center bottom;width:100%;filter:grayscale(90%);border-right:0;z-index:0;}#color{position:absolute;background:", ";top:0;height:100%;width:100%;mix-blend-mode:soft-light;}"], function (props) {
  return props.theme.background;
}, function (props) {
  return props.theme.background;
}, function (props) {
  return props.theme.main;
});